import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../layouts';
import Seo from '../components/seo';
import GrayWoodSection from '../components/Section/GrayWoodSection';
import {
  BreadCrumb,
  Section,
  SectionLead,
  SectionPageTitle,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
  FlexWrap,
  LeftWrap,
  RightWrap,
} from '../components/Section';
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton';
import SecondButton from '../components/Button/SecondButton';
import LineArrowRight from '../components/Icons/LineArrowRight';
import PhoneIcon from '../components/Icons/PhoneIcon';
import WhySection from '../sections/Home/WhySection';

const HeroTitle = styled(SectionPageTitle)`
	max-width: 650px;
`

const HeroFeatures = styled.div`
	display: flex;
	width: fit-content;
	margin: 0 auto;
	align-items: center;

	.item {
		padding: 5px 24px;
		border-right: 1px solid #000000;
		font-size: 30px;
		line-height: 45px;
		font-weight: 600;
		color: #000000;
		text-align: center;

		&:last-child {
			border-right: 0;
		}
	}

	@media(max-width: 1300px) {
		flex-wrap: wrap;
		justify-content: center;
		max-width: 750px;

		.item {
			&:nth-child(2) {
				border-right: 0;
			}
		}
	}
	@media(max-width: 768px) {
		.item {
			width: 100%;
			max-width: 480px;
			margin: 0 auto 15px;
			padding-bottom: 15px;
			border-right: 0;
			border-bottom: 1px solid #000000;
			font-size: 24px;
			line-height: 36px;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
	}
`

const AssistanceWrap = styled(LeftWrap)`
	border-right: 1px solid #ffffff;

	h2 {
		max-width: 100%;
		width: 100%;
		margin: 20px 0 30px;

		@media(min-width: 991px) {
			max-width: 500px;
		}
	}
	@media(max-width: 991px) {
		margin-bottom: 30px;
	}
`

const AboutUsPage = ({ data, location}) => {
	const pageData = data.contentfulAboutUsPage
	const organizationSchema = {
		"@context": "https://schema.org", 
		"@type": "Organization", 
		"name": "Buildings And More", 
		"url": "https://www.buildingsandmore.com",
		"logo": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/df682/logo.png",
		"contactPoint": { 
		"@type": "ContactPoint", 
		"telephone": "+1 (386) 755-6449", 
		"contactType": "customer service", 
		"areaServed": "US", 
		"availableLanguage": "en"
		}, 
		"sameAs": [
		  "https://www.facebook.com/BuildingsAndMore/", 
		  "https://www.instagram.com/buildingsandmorefl/", 
		  "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
		  "https://www.pinterest.com/buildingsandmore/", 
		  "https://www.buildingsandmore.com/" 
		] 
	}
	const businessSchema = { 
	"@context": "https://schema.org", 
	"@type": "LocalBusiness", 
	"name": "Buildings And More", 
	"image": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/8abb9/logo.webp", 
	"@id": "", 
	"url": "https://www.buildingsandmore.com/", 
	"telephone": "+1 (386) 755-6449", 
	"address": { 
		"@type": "PostalAddress", 
		"streetAddress": "792 SW Bascom Norris Drive", 
		"addressLocality": "Lake City", 
		"addressRegion": "FL", 
		"postalCode": "32025", 
		"addressCountry": "US" 
	}, 
	"geo": { 
		"@type": "GeoCoordinates", 
		"latitude": 30.1720092, 
		"longitude": -82.6699471 
	}, 
	"openingHoursSpecification": [{ 
		"@type": "OpeningHoursSpecification", 
		"dayOfWeek": [ 
		"Monday", 
		"Tuesday", 
		"Wednesday", 
		"Thursday", 
		"Friday" 
		], 
		"opens": "09:00", 
		"closes": "17:00" 
	},{ 
		"@type": "OpeningHoursSpecification", 
		"dayOfWeek": "Saturday", 
		"opens": "09:00", 
		"closes": "14:00" 
	}],
	"sameAs": [
		"https://www.facebook.com/BuildingsAndMore/", 
		"https://www.instagram.com/buildingsandmorefl/", 
		"https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
		"https://www.pinterest.com/buildingsandmore/", 
		"https://www.buildingsandmore.com/" 
	] 
	}
	const schema = [organizationSchema, businessSchema]
	return (
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} schemaMarkup={schema} />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>{pageData.pageName}</span>
				</div>
			</BreadCrumb>
			<GrayWoodSection>
					<div className="container">
						<SectionLead color="#E43D3C" mb="10px">
							{pageData.pageName}
						</SectionLead>
						<HeroTitle>
							{pageData.heroTitle}
						</HeroTitle>
						<SectionDescription maxWidth="950px">
							<div dangerouslySetInnerHTML={{__html: pageData.heroDescription.childMarkdownRemark.html}} />
						</SectionDescription>
						<HeroFeatures>
						{
							pageData.heroFeatures.map((item, i) => (
								<div className="item" key={i}>
									{item.content}
								</div>
							))
						}
						</HeroFeatures>
					</div>
			</GrayWoodSection>
			<Section bgColor="#381F1F" pt="100px" pb="100px" spt="42px" spb="31px">
				<div className="container">
					<FlexWrap alignItems="center">
						<AssistanceWrap>
							<SectionLead textAlign="left" color="#ffffff" mb="10px">
								Get your dream building installed at your site
							</SectionLead>
							<SectionTitle textAlign="left" color="#ffffff" >
								Call today to talk to our building experts
							</SectionTitle>
							<a href="tel:3867556499">
								<SecondButton text="(386) 755-6449" icon={<PhoneIcon fill="#ffffff" />} />
							</a>
						</AssistanceWrap>
						<RightWrap>
							{pageData.videoSection.label && 
								<SectionLead textAlign="left" color="#E43D3C" mb="10px">
									{pageData.videoSection.label}
								</SectionLead>
							}
							<SectionTitle textAlign="left" color="#ffffff">{pageData.videoSection.title}</SectionTitle>
							<SectionDescription  textAlign="left" color="#ffffff">
								<div dangerouslySetInnerHTML={{__html: pageData.videoSection.description.childMarkdownRemark.html}} />
							</SectionDescription>
							{pageData.videoSection.buttonText &&
								<Link to={pageData.videoSection.buttonUrl}>
									<PrimaryLinkButton text={pageData.videoSection.buttonText} icon={<LineArrowRight />} />
								</Link>
							}
						</RightWrap>
					</FlexWrap>
				</div>
			</Section>
			<WhySection data={pageData.whyBamSection} />
			<GrayWoodSection>
				<div className="container">
					<MarkdownContent maxWidth="1024px">
						<div dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}} />
					</MarkdownContent>
				</div>
			</GrayWoodSection>
		</Layout>
	)
};

export default AboutUsPage

export const query = graphql`
  query AboutUsPageQuery {
    contentfulAboutUsPage {
      metaTitle
      metaDescription
      pageName
      heroTitle
      heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    heroFeatures {
	      content
	    }
	    videoSection {
        videoUrl
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      whyBamSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        videoUrl
        image {
          file {
            url
          }
        }
        buttonUrl
        buttonText
        features {
          icon {
            file {
              url
            }
          }
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      processSection {
	      label
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      data {
	        feature
	        description
	      }
	    }
	    content {
	    	childMarkdownRemark {
          html
        }
	    }
    }
  }
`